import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'reactstrap'
import CountUp from 'react-countup'
import { useSelector } from 'react-redux'
import { State } from 'src/store'

function BalanceStatus() {
  const lending = useSelector((state: State) => state.ceoDecisions.data.decisions?.lending || 0)
  const finalCash = useSelector((state: State) => state.ceoDecisions.schema.final_cash?.value) || 0
  const lineCredit = useSelector((state: State) => state.ceoDecisions.schema.line_credit?.value) || 0
  const totalFunds = useSelector((state: State) => state.ceoDecisions.funds) || 0
  const balance = finalCash * (totalFunds / 100) + lending

  return (
    <Card className="card-box" style={{ minWidth: 320 }}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="pr-3 pb-2 pt-2 pl-3">
          <div className="divider-v divider-v-lg" />
          <div>
            <div className="d-flex">
              <FontAwesomeIcon icon={['fas', 'dollar-sign']} className="display-3 text-success" />
              <div className="ml-3 line-height-sm">
                <b className="font-size-lg opacity-8">
                  <CountUp
                    start={0}
                    end={balance}
                    duration={2}
                    separator=","
                    decimals={0}
                    decimal="."
                    prefix=""
                    suffix=""
                  />
                </b>
                <span className="text-black-50 d-block">Caja</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pr-3 pb-2 pt-2 pl-3">
          <div>
            <div className="d-flex">
              <FontAwesomeIcon icon={['fas', 'hand-holding-usd']} className="display-3 text-danger" />
              <div className="ml-3 line-height-sm">
                <b className="font-size-lg opacity-8">
                  <CountUp
                    start={0}
                    end={lineCredit}
                    duration={0.5}
                    separator=","
                    decimals={0}
                    decimal="."
                    prefix=""
                    suffix=""
                  />
                </b>
                <span className="text-black-50 d-block">Línea de crédito</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export { BalanceStatus }
