export const ACCEPT_COMMUNITY_INVITATION_LOADING = 'ACCEPT_COMMUNITY_INVITATION_LOADING'
export const ACCEPT_COMMUNITY_INVITATION_FAIL = 'ACCEPT_COMMUNITY_INVITATION_FAIL'
export const ACCEPT_COMMUNITY_INVITATION_SUCCESS = 'ACCEPT_COMMUNITY_INVITATION_SUCCESS'

export interface AcceptCommunityInvitationLoading {
  type: typeof ACCEPT_COMMUNITY_INVITATION_LOADING;
}

export interface AcceptCommunityInvitationFail {
  type: typeof ACCEPT_COMMUNITY_INVITATION_FAIL;
}

export interface AcceptCommunityInvitationSuccess {
  type: typeof ACCEPT_COMMUNITY_INVITATION_SUCCESS;
}

export type AcceptCommunityInvitationDispatchTypes =
  | AcceptCommunityInvitationLoading
  | AcceptCommunityInvitationFail
  | AcceptCommunityInvitationSuccess
